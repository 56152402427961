import type { ClassValue } from 'clsx'
import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'

import styles from './container.module.scss'

interface ContainerProps extends PropsWithChildren {
  tag?: string
  className?: ClassValue
}

const Container: FunctionComponent<ContainerProps> = (
  {
    tag,
    children,
    className
  }
) => {
  const Tag = tag || 'main'

  return <Tag
    id={tag === undefined ? 'content' : undefined}
    className={clsx(
      styles.container,
      className
    )}
  >
    {children}
  </Tag>
}

export default Container

export type {
  ContainerProps
}
